<template>
  <div class="modal-wrap mobile-full" :class="{ active: isShowTerm }">
    <div class="modal" @click="closeModal"></div>
    <div class="modal-alert-wrap term">
      <div class="modal-head">
        <h2>{{ title }}</h2>
        <button type="button" @click="closeModal" class="btn-modal-close">
          <img src="@/assets/images/common/ico-24-close.svg" class="ico-24" alt="close" />
        </button>
      </div>
      <div class="modal-cont" v-html="data.content"></div>
    </div>
  </div>
</template>

<script>
import { postData } from '@/serverApi';
import { useI18n } from 'vue-i18n';

export default {
  name: 'ModalTerm',
  props: {
    modalType: String,
  },
  data() {
    const { t } = useI18n();
    return {
      t,
      isShowTerm: false,
      data: {},
      title: '',
    };
  },
  mounted() {},
  watch: {
    isShowTerm(val) {
      if (val) {
        this.loadData();
      }
    },
    $route: {
      handler() {
        this.closeModal();
      },
    },
  },
  methods: {
    closeModal() {
      this.isShowTerm = false;
      document.documentElement.classList.remove('modal-overflow');
      document.body.classList.remove('modal-overflow');
    },
    loadData() {
      this.$log.debug(['loadData', this.modalType]);
      switch (this.modalType) {
        case 'terms':
          this.title = this.t('system.term_2');
          break;
        case 'privacy':
          this.title = this.t('system.term_3');
          break;
        case 'marketing':
          this.title = this.t('system.term_4');
          break;
      }

      const param = {
        board_type: this.modalType,
        locale: this.$i18n.locale,
      };
      postData('/board/detailByType', param).then((res) => {
        this.$log.debug(['postData', res]);
        this.data = res.data;
      });
    },
  },
};
</script>

<style scoped></style>
