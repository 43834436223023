import { createStore } from 'vuex';
import persistedStateVuex from 'persisted-state-vuex';

export default createStore({
  state: {
    counter: 10,
    signUpType: '',
    certInfo: null,
    certType: null,
    certPwID: null, // 비밀번호 찾기 시 입력 아이디
    agreeTerm: null, //  회원가입 시 동의여부 확인
    passCheck: null, //  회원정보 시 비밀번호 확인
    userInfo: null,
    findInfo: null,
    obmRequest: null,
    currentLocale: null,
    odmRequest: {},
    odmRequestDetail: {},
    logoFile: File,
    refFiles: [],
  },
  getters: {
    time2(state) {
      return state.counter * 2;
    },
    getSignUpType(state) {
      return state.signUpType;
    },
    getCertInfo(state) {
      return state.certInfo;
    },
    getCertType(state) {
      return state.certType;
    },
    getCertPwID(state) {
      return state.certPwID;
    },
    getAgreeTerm(state) {
      return state.agreeTerm;
    },
    getPassCheck(state) {
      return state.passCheck;
    },
    getUserInfo(state) {
      return state.userInfo;
    },
    getFindInfo(state) {
      return state.findInfo;
    },
    getOdmRequest(state) {
      return state.odmRequest;
    },
    getOdmRequestDetail(state) {
      return state.odmRequestDetail;
    },
    getObmRequest(state) {
      return state.obmRequest;
    },
    getLogoFile(state) {
      return state.logoFile;
    },
    getRefFile(state) {
      return state.refFiles;
    },
    getCurrentLocale(state) {
      return state.currentLocale;
    },
  },
  mutations: {
    setCounter(state, value) {
      state.counter = value;
      state.dispatch('setCounter', value);
    },
    setSignUpType(state, value) {
      state.signUpType = value;
    },
    setCertInfo(state, value) {
      state.certInfo = value;
    },
    setCertType(state, value) {
      state.certType = value;
    },
    setCertPwId(state, value) {
      state.certPwID = value;
    },
    setAgreeTerm(state, value) {
      state.agreeTerm = value;
    },
    setPassCheck(state, value) {
      state.passCheck = value;
    },
    setUserInfo(state, value) {
      state.userInfo = value;
      // state.dispatch('setLogoFile', value);
    },
    setFindInfo(state, value) {
      state.findInfo = value;
    },
    setOdmRequest(state, value) {
      state.odmRequest = value;
    },
    setOdmRequestDetail(state, value) {
      state.odmRequestDetail = value;
    },
    setObmRequest(state, value) {
      state.obmRequest = value;
    },
    setLogoFile(state, value) {
      console.log(['mutations getLogoFile', value, state]);
      state.logoFile = value;
      // state.dispatch('setLogoFile', value);
    },
    setRefFile(state, value) {
      state.refFiles = value;
    },
    setCurrentLocale(state, value) {
      state.currentLocale = value;
    },
  },
  actions: {
    setCounter(state, value) {
      state.counter = value;
    },
    setSignUpType(state, value) {
      state.signUpType = value;
    },
    setCertInfo(state, value) {
      state.certInfo = value;
    },
    setUserInfo(state, value) {
      state.userInfo = value;
    },
    setOdmRequest(state, value) {
      state.odmRequest = value;
    },
    setOdmRequestDetail(state, value) {
      state.odmRequestDetail = value;
    },
    setObmRequest(state, value) {
      state.obmRequest = value;
    },
    setLogoFile(context, value) {
      console.log(['store setLogoFile', value]);
      context.commit('setLogoFile', value);
      // state.logoFile = value;
    },
    setRefFile(state, value) {
      state.refFiles = value;
    },
  },
  plugins: [persistedStateVuex.init],
});
