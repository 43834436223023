<template>
  <!-- header : start -->
  <header id="header">
    <div class="header-cont">
      <div class="cont-wrap">
        <button type="button" @click="openMenu" class="btn-gnb" title="open menu">
          <img src="@/assets/images/common/ico-24-gnb.svg" class="ico-24" alt="open menu" />
        </button>
        <h1 class="logo">
          <router-link to="/Main">
            <img src="@/assets/images/common/logo.svg" alt="cosmocos lab logo" />
          </router-link>
        </h1>
        <div class="snb-wrap">
          <ul class="snb">
            <li v-show="isLogin === true">
              {{ userInfo.user_id }}
            </li>
            <li v-show="isLogin === true">
              <button type="button" @click="logout">LOGOUT</button>
            </li>
            <li v-show="isLogin === true">
              <router-link to="/mypage/MyRequest"> MY REQUEST </router-link>
            </li>
            <li v-show="isLogin === false">
              <a @click="login"> LOGIN </a>
              <!--              <router-link to="/auth/Login"> LOGIN </router-link>-->
            </li>
            <li v-show="isLogin === false">
              <router-link to="/auth/SignUp"> SIGN UP </router-link>
            </li>
            <li>
              <button @click="changeLocale">
                {{ this.$i18n.locale === 'en' ? 'ENG' : 'KOR' }}
              </button>
            </li>
          </ul>
          <button type="button" class="btn-search" @click="openSearchBox" title="open search box">
            <img src="@/assets/images/common/ico-24-search.svg" class="ico-24" alt="search" />
          </button>
        </div>
        <!-- close .snb-wrap -->
      </div>
      <!-- close .cont-wrap -->
    </div>
    <!-- close .header-cont -->
    <div class="top-navi" :class="{ active: isTopNaviHoverBind }">
      <div class="cont-wrap">
        <ul>
          <!-- 실제   -->
          <li @mouseenter="hoverTopNavi" @mouseleave="hoverTopNavi" v-for="(parent, i) of menus" :key="i">
            <a href="javascript:" @click="accessMenu(parent)" :to="parent.menu_path" class="eng">
              <span>{{ parent.menu_name_ko }}</span>
            </a>
            <ul class="depth2 depth2-topNavi">
              <li v-for="(menu, j) of parent.children" :key="j">
                <router-link to="/open/MyItem" v-if="parent.menu_seq === 3 && j === 0 && isPartner">
                  <span>MY ITEM</span>
                </router-link>
                <a href="javascript:;" @click="accessMenu(menu)" v-if="showRequest(menu)">
                  <span>{{ menu.menu_name_ko }}</span>
                </a>
              </li>
            </ul>
          </li>
          <!-- [pub]   -->
          <!--          <li @mouseenter="hoverTopNavi" @mouseleave="hoverTopNavi">-->
          <!--            <router-link to="/about/Lab" class="eng"> ABOUT </router-link>-->
          <!--            <ul class="depth2 depth2-topNavi">-->
          <!--              <li>-->
          <!--                <router-link to="/about/Lab"> COSMOCOS LAB </router-link>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link to="/about/Odm"> COSMOCOS </router-link>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link to="/about/Partner"> OUR PARTNERS </router-link>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link to="/about/Notice"> NOTICE </router-link>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <!--          <li @mouseenter="hoverTopNavi" @mouseleave="hoverTopNavi">-->
          <!--            <router-link to="/odm/Formula" class="eng"> ODM </router-link>-->
          <!--            <ul class="depth2 depth2-topNavi">-->
          <!--              <li>-->
          <!--                <router-link to="/odm/Formula"> FORMULA </router-link>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link to="/odm/IngredientAll"> INGREDIENT </router-link>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link to="/odm/Request01"> ODM REQUEST </router-link>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <button type="button" @click="action('guide', 'ODM')">-->
          <!--                  ODM GUIDE-->
          <!--                </button>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <!--          <li @mouseenter="hoverTopNavi" @mouseleave="hoverTopNavi">-->
          <!--            <router-link to="/open/FormulaAll" class="eng">-->
          <!--              OPEN ODM-->
          <!--            </router-link>-->
          <!--            <ul class="depth2 depth2-topNavi">-->
          <!--              &lt;!&ndash; v-if Open ODM 권한 있을 경우에만 노출 &ndash;&gt;-->
          <!--              <li>-->
          <!--                <router-link to="/open/MyItem"> MY ITEM </router-link>-->
          <!--              </li>-->
          <!--              &lt;!&ndash; v-if Open ODM 권한 있을 경우에만 노출 : 종료 &ndash;&gt;-->
          <!--              <li>-->
          <!--                <router-link to="/open/FormulaAll"> FORMULA </router-link>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link to="/open/IngredientAll"> INGREDIENT </router-link>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link to="/open/PackagingAll"> PACKAGING </router-link>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <button type="button" @click="action('guide', 'OPEN')">-->
          <!--                  OPEN ODM GUIDE-->
          <!--                </button>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <!--          <li @mouseenter="hoverTopNavi" @mouseleave="hoverTopNavi">-->
          <!--            <router-link to="/obm/Inventory" class="eng"> OBM </router-link>-->
          <!--            <ul class="depth2 depth2-topNavi">-->
          <!--              <li>-->
          <!--                <router-link to="/obm/Inventory"> BRAND INVENTORY </router-link>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link to="/obm/Request01"> OBM REQUEST </router-link>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <button type="button" @click="action('guide', 'OBM')">-->
          <!--                  OBM GUIDE-->
          <!--                </button>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <!--          <li @mouseenter="hoverTopNavi" @mouseleave="hoverTopNavi">-->
          <!--            <router-link to="/trend/Report" class="eng">-->
          <!--              TREND & NEWS-->
          <!--            </router-link>-->
          <!--            <ul class="depth2 depth2-topNavi">-->
          <!--              <li>-->
          <!--                <router-link to="/trend/Report"> TREND REPORT </router-link>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link to="/trend/News"> NEWS LETTER </router-link>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link to="/trend/Request">-->
          <!--                  INVENTORY REQUEST-->
          <!--                </router-link>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->

          <li @mouseenter="hoverTopNavi" @mouseleave="hoverTopNavi" v-if="userInfo.user_id">
            <router-link to="/mypage/MyRequest" class="eng"> MY PAGE </router-link>
            <ul class="depth2">
              <li>
                <router-link to="/mypage/CheckAuth">
                  {{ $t('gnb.mem_info') }}
                </router-link>
              </li>
              <li>
                <router-link to="/mypage/MyRequest"> MY REQUEST </router-link>
              </li>
              <li>
                <router-link to="/mypage/WishList"> MY FOLDER </router-link>
              </li>
              <li>
                <router-link to="/mypage/RecentList">
                  {{ $t('gnb.recent') }}
                </router-link>
              </li>
              <li>
                <router-link to="/mypage/Inquery">
                  {{ $t('gnb.inquery') }}
                </router-link>
              </li>
              <!-- <li>
                    <router-link to="/common/Faq">
                        FAQ
                    </router-link>
                </li> -->
            </ul>
          </li>
        </ul>
      </div>
      <!-- close .cont-wrap -->
    </div>
    <!-- close .top-navi -->
  </header>
  <!-- close #header -->

  <div
    class="gnb-wrap"
    :class="{ active: isGnbBind }"
    :style="{
      'background-image': 'url(' + require(`@/assets/images/common/bg/bg${selectedImg}.jpg`) + ')',
    }"
  >
    <!--
        배경 이미지 랜덤 노출
        @/assets/images/common/bg/bg01.jpg
        @/assets/images/common/bg/bg02.jpg
        @/assets/images/common/bg/bg03.jpg
        @/assets/images/common/bg/bg04.jpg
        @/assets/images/common/bg/bg05.jpg
        @/assets/images/common/bg/bg06.jpg
        @/assets/images/common/bg/bg07.jpg
        @/assets/images/common/bg/bg08.jpg
        @/assets/images/common/bg/bg09.jpg
        @/assets/images/common/bg/bg10.jpg
    -->
    <div class="w-modal"></div>
    <div class="cont-wrap">
      <div class="gnb-box">
        <button type="button" @click="closeMenu" class="btn-gnb-close">
          <img src="@/assets/images/common/ico-24-close.svg" class="ico-24" alt="close" />
        </button>
        <nav class="gnb">
          <Accordion :multiple="true" :activeIndex="tabActive">
            <AccordionTab v-for="(parent, i) of menus" :key="i">
              <template #header>
                <button type="button" class="eng">{{ parent.menu_name_ko }}</button>
              </template>
              <ul class="depth2">
                <li v-for="(menu, j) of parent.children" :key="j">
                  <router-link to="/open/MyItem" v-if="parent.menu_seq === 3 && j === 0 && isPartner">
                    <span>MY ITEM</span>
                  </router-link>
                  <a href="javascript:;" @click="accessMenu(menu)" v-if="showRequest(menu)">
                    <span>{{ menu.menu_name_ko }}</span>
                  </a>
                </li>
              </ul>
            </AccordionTab>
            <div v-if="userInfo.user_id">
              <AccordionTab>
                <template #header>
                  <button type="button" class="eng">MY PAGE</button>
                </template>
                <ul class="depth2">
                  <li>
                    <router-link to="/mypage/CheckAuth">
                      {{ $t('gnb.mem_info') }}
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/mypage/MyRequest"> MY REQUEST </router-link>
                  </li>
                  <li>
                    <router-link to="/mypage/WishList"> MY FOLDER </router-link>
                  </li>
                  <li>
                    <router-link to="/mypage/RecentList">
                      {{ $t('gnb.recent') }}
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/mypage/Inquery">
                      {{ $t('gnb.inquery') }}
                    </router-link>
                  </li>
                  <!-- <li>
                                    <router-link to="/common/Faq">
                                        FAQ
                                    </router-link>
                                </li> -->
                </ul>
              </AccordionTab>
            </div>
          </Accordion>

          <!--          <Accordion :multiple="true" :activeIndex="tabActive">-->
          <!--            <AccordionTab>-->
          <!--              <template #header>-->
          <!--                <button type="button" class="eng">ABOUT</button>-->
          <!--              </template>-->
          <!--              <ul class="depth2">-->
          <!--                <li>-->
          <!--                  <router-link to="/about/Lab"> COSMOCOS LAB </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/about/Odm"> COSMOCOS </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/about/Partner"> OUR PARTNERS </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/about/Notice"> NOTICE </router-link>-->
          <!--                </li>-->
          <!--              </ul>-->
          <!--            </AccordionTab>-->
          <!--            <AccordionTab>-->
          <!--              <template #header>-->
          <!--                <button type="button" class="eng">ODM</button>-->
          <!--              </template>-->
          <!--              <ul class="depth2">-->
          <!--                <li>-->
          <!--                  <router-link to="/odm/Formula"> FORMULA </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/odm/IngredientAll"> INGREDIENT </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/odm/Request01"> ODM REQUEST </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <button type="button" @click="action('guide', 'ODM')">ODM GUIDE</button>-->
          <!--                </li>-->
          <!--              </ul>-->
          <!--            </AccordionTab>-->
          <!--            <AccordionTab>-->
          <!--              <template #header>-->
          <!--                <button type="button" class="eng">OPEN ODM</button>-->
          <!--              </template>-->
          <!--              <ul class="depth2">-->
          <!--                &lt;!&ndash; v-if Open ODM 권한 있을 경우에만 노출 &ndash;&gt;-->
          <!--                <li>-->
          <!--                  <router-link to="/open/MyItem"> MY ITEM </router-link>-->
          <!--                </li>-->
          <!--                &lt;!&ndash; v-if Open ODM 권한 있을 경우에만 노출 : 종료 &ndash;&gt;-->
          <!--                <li>-->
          <!--                  <router-link to="/open/FormulaAll"> FORMULA </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/open/IngredientAll"> INGREDIENT </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/open/PackagingAll"> PACKAGING </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <button type="button" @click="action('guide', 'OPEN')">OPEN ODM GUIDE</button>-->
          <!--                </li>-->
          <!--              </ul>-->
          <!--            </AccordionTab>-->
          <!--            <AccordionTab>-->
          <!--              <template #header>-->
          <!--                <button type="button" class="eng">OBM</button>-->
          <!--              </template>-->
          <!--              <ul class="depth2">-->
          <!--                <li>-->
          <!--                  <router-link to="/obm/Inventory"> BRAND INVENTORY </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/obm/Request01"> OBM REQUEST </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <button type="button" @click="action('guide', 'OBM')">OBM GUIDE</button>-->
          <!--                </li>-->
          <!--              </ul>-->
          <!--            </AccordionTab>-->
          <!--            <AccordionTab>-->
          <!--              <template #header>-->
          <!--                <button type="button" class="eng">TREND & NEWS</button>-->
          <!--              </template>-->
          <!--              <ul class="depth2">-->
          <!--                <li>-->
          <!--                  <router-link to="/trend/Report"> TREND REPORT </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/trend/News"> NEWS LETTER </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/trend/Request"> INVENTORY REQUEST </router-link>-->
          <!--                </li>-->
          <!--              </ul>-->
          <!--            </AccordionTab>-->
          <!--            <AccordionTab>-->
          <!--              <template #header>-->
          <!--                <button type="button" class="eng">MY PAGE</button>-->
          <!--              </template>-->
          <!--              <ul class="depth2">-->
          <!--                <li>-->
          <!--                  <router-link to="/mypage/CheckAuth">-->
          <!--                    {{ $t('gnb.mem_info') }}-->
          <!--                  </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/mypage/MyRequest"> MY REQUEST </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/mypage/WishList"> MY FOLDER </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/mypage/RecentList">-->
          <!--                    {{ $t('gnb.recent') }}-->
          <!--                  </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/mypage/Inquery">-->
          <!--                    {{ $t('gnb.inquery') }}-->
          <!--                  </router-link>-->
          <!--                </li>-->
          <!--                &lt;!&ndash; <li>-->
          <!--                                    <router-link to="/common/Faq">-->
          <!--                                        FAQ-->
          <!--                                    </router-link>-->
          <!--                                </li> &ndash;&gt;-->
          <!--              </ul>-->
          <!--            </AccordionTab>-->
          <!--          </Accordion>-->
        </nav>
        <div class="snb-wrap">
          <ul class="snb">
            <li v-show="isLogin === true">
              <button type="button" @click="logout">LOGOUT</button>
            </li>
            <!-- <li v-show="isLogin === true">
                            <router-link to="/mypage/CheckAuth">
                                MYPAGE
                            </router-link>
                        </li> -->
            <li v-show="isLogin === false">
              <router-link to="/auth/Login"> LOGIN </router-link>
            </li>
            <li v-show="isLogin === false">
              <router-link to="/auth/SignUp"> SIGN UP </router-link>
            </li>
            <!--            <li v-if="isDev">-->
            <!--              <ul>-->
            <!--                <li>-->
            <!--                  <button @click="changeLocale">-->
            <!--                    {{ this.$i18n.locale === 'en' ? 'ENG' : 'KOR' }}-->
            <!--                  </button>-->
            <!--                </li>-->
            <!--                <li>-->
            <!--                  <button @click="changeLocale">-->
            <!--                    {{ this.$i18n.locale === 'en' ? 'ENG' : 'KOR' }}-->
            <!--                  </button>-->
            <!--                </li>-->
            <!--              </ul>-->
            <!--            </li>-->
            <!--            <li v-else>-->
            <li>
              <button @click="changeLocale">
                {{ this.$i18n.locale === 'en' ? 'ENG' : 'KOR' }}
              </button>
            </li>
          </ul>
        </div>
        <!-- close .snb-wrap -->
      </div>
      <!-- close .gnb-box -->
    </div>
    <!-- close .cont-wrap -->
  </div>
  <!-- close .gnb-wrap -->
  <!--  <div class="gnb-wrap" :class="{ active: isGnbBind }">-->
  <!--    <div class="w-modal"></div>-->
  <!--    <div class="cont-wrap">-->
  <!--      <div class="gnb-box">-->
  <!--        <button type="button" @click="closeMenu" class="btn-gnb-close">-->
  <!--          <img src="@/assets/images/common/ico-24-close.svg" class="ico-24" alt="close" />-->
  <!--        </button>-->
  <!--        <nav class="gnb">-->
  <!--          <Accordion :multiple="true" :activeIndex="tabActive">-->
  <!--            <AccordionTab>-->
  <!--              <template #header>-->
  <!--                <button type="button" class="eng">ABOUT</button>-->
  <!--              </template>-->
  <!--              <ul class="depth2">-->
  <!--                <li>-->
  <!--                  <router-link to="/about/Lab"> COSMOCOS LAB </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <router-link to="/about/Odm"> COSMOCOS </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <router-link to="/about/Partner"> OUR PARTNERS </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <router-link to="/about/Notice"> NOTICE </router-link>-->
  <!--                </li>-->
  <!--              </ul>-->
  <!--            </AccordionTab>-->
  <!--            <AccordionTab>-->
  <!--              <template #header>-->
  <!--                <button type="button" class="eng">ODM</button>-->
  <!--              </template>-->
  <!--              <ul class="depth2">-->
  <!--                <li>-->
  <!--                  <router-link to="/odm/Formula"> FORMULA </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <router-link to="/odm/IngredientAll"> INGREDIENT </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <router-link to="/odm/Request01"> ODM REQUEST </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <button type="button">ODM GUIDE</button>-->
  <!--                </li>-->
  <!--              </ul>-->
  <!--            </AccordionTab>-->
  <!--            <AccordionTab>-->
  <!--              <template #header>-->
  <!--                <button type="button" class="eng">OPEN ODM</button>-->
  <!--              </template>-->
  <!--              <ul class="depth2">-->
  <!--                &lt;!&ndash; v-if Open ODM 권한 있을 경우에만 노출 &ndash;&gt;-->
  <!--                <li>-->
  <!--                  <router-link to="/open/MyItem"> MY ITEM </router-link>-->
  <!--                </li>-->
  <!--                &lt;!&ndash; v-if Open ODM 권한 있을 경우에만 노출 : 종료 &ndash;&gt;-->
  <!--                <li>-->
  <!--                  <router-link to="/open/FormulaAll"> FORMULA </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <router-link to="/open/IngredientAll"> INGREDIENT </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <router-link to="/open/PackagingAll"> PACKAGING </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <button type="button">OPEN ODM GUIDE</button>-->
  <!--                </li>-->
  <!--              </ul>-->
  <!--            </AccordionTab>-->
  <!--            <AccordionTab>-->
  <!--              <template #header>-->
  <!--                <button type="button" class="eng">OBM</button>-->
  <!--              </template>-->
  <!--              <ul class="depth2">-->
  <!--                <li>-->
  <!--                  <router-link to="/obm/Inventory"> BRAND INVENTORY </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <router-link to="/obm/Request01"> OBM REQUEST </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <button type="button">OBM GUIDE</button>-->
  <!--                </li>-->
  <!--              </ul>-->
  <!--            </AccordionTab>-->
  <!--            <AccordionTab>-->
  <!--              <template #header>-->
  <!--                <button type="button" class="eng">TREND & NEWS</button>-->
  <!--              </template>-->
  <!--              <ul class="depth2">-->
  <!--                <li>-->
  <!--                  <router-link to="/trend/Report"> TREND REPORT </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <router-link to="/trend/News"> NEWS LETTER </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <router-link to="/trend/Request"> INVENTORY REQUEST </router-link>-->
  <!--                </li>-->
  <!--              </ul>-->
  <!--            </AccordionTab>-->
  <!--            <AccordionTab>-->
  <!--              <template #header>-->
  <!--                <button type="button" class="eng">MY PAGE</button>-->
  <!--              </template>-->
  <!--              <ul class="depth2">-->
  <!--                <li>-->
  <!--                  <router-link to="/mypage/CheckAuth">-->
  <!--                    {{ $t('gnb.mem_info') }}-->
  <!--                  </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <router-link to="/mypage/MyRequest"> MY REQUEST </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <router-link to="/mypage/WishList"> WISH LIST </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <router-link to="/mypage/RecentList">-->
  <!--                    {{ $t('gnb.recent') }}-->
  <!--                  </router-link>-->
  <!--                </li>-->
  <!--                <li>-->
  <!--                  <router-link to="/mypage/Inquery">-->
  <!--                    {{ $t('gnb.inquery') }}-->
  <!--                  </router-link>-->
  <!--                </li>-->
  <!--                &lt;!&ndash; <li>-->
  <!--                    <router-link to="/common/Faq">-->
  <!--                        FAQ-->
  <!--                    </router-link>-->
  <!--                </li> &ndash;&gt;-->
  <!--              </ul>-->
  <!--            </AccordionTab>-->
  <!--          </Accordion>-->
  <!--        </nav>-->
  <!--        <div class="snb-wrap">-->
  <!--          <ul class="snb">-->
  <!--            <li v-show="isLogin === true">-->
  <!--              <button type="button">LOGOUT</button>-->
  <!--            </li>-->
  <!--            &lt;!&ndash; <li v-show="isLogin === true">-->
  <!--                <router-link to="/mypage/CheckAuth">-->
  <!--                    MYPAGE-->
  <!--                </router-link>-->
  <!--            </li> &ndash;&gt;-->
  <!--            <li v-show="isLogin === false">-->
  <!--              <router-link to="/auth/Login"> LOGIN </router-link>-->
  <!--            </li>-->
  <!--            <li v-show="isLogin === false">-->
  <!--              <router-link to="/auth/SignUp"> SIGN UP </router-link>-->
  <!--            </li>-->
  <!--            <li>-->
  <!--              <button @click="changeLocale">-->
  <!--                {{ this.$i18n.locale === 'en' ? 'ENG' : 'KOR' }}-->
  <!--              </button>-->
  <!--            </li>-->
  <!--          </ul>-->
  <!--        </div>-->
  <!--        &lt;!&ndash; close .snb-wrap &ndash;&gt;-->
  <!--      </div>-->
  <!--      &lt;!&ndash; close .gnb-box &ndash;&gt;-->
  <!--    </div>-->
  <!--    &lt;!&ndash; close .cont-wrap &ndash;&gt;-->
  <!--  </div>-->
  <!-- close .gnb-wrap -->

  <SearchBar ref="refSearch" />
  <!-- close .search-wrap -->
</template>

<script>
// import { Form, Field } from 'vee-validate';
import SearchBar from '@/components/SearchBar.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { getData, postData } from '@/serverApi';
import { useMenuAccessStore } from '@/stores/menuAccess';
import { useI18n } from 'vue-i18n';
export default {
  name: 'AppHeader',
  components: {
    SearchBar,
  },
  data() {
    const { t } = useI18n();
    const store = useCurrentPageStore();
    const storeMenu = useMenuAccessStore();
    const { setCurrentPage } = store;
    const { getAccessMenu, setAccessMenu } = storeMenu;
    return {
      t,
      // currentLocale: this.$i18n.locale === 'en' ? 'EN' : 'KO',
      setCurrentPage,
      getAccessMenu,
      setAccessMenu,
      isLogin: false,
      isGnbBind: false,
      isSearchBind: false,
      isTopNaviHoverBind: false,
      debouncedHeight: 0,
      debouncedWidth: 0,
      heightTimeout: null,
      widthTimeout: null,
      recommendList: [{ tag: '#해시태그' }, { tag: '#해시태그' }, { tag: '#해시태그' }, { tag: '#해시태그' }],
      popularList1: [
        { lank: '1위', keyword: '#실시간 인기 검색어' }, // 1위,,,, 10위 국문은 [1위] 영문은 1.
        { lank: '2위', keyword: '#실시간 인기 검색어' },
        { lank: '3위', keyword: '#실시간 인기 검색어' },
        { lank: '4위', keyword: '#실시간 인기 검색어' },
        { lank: '5위', keyword: '#실시간 인기 검색어' },
      ],
      popularList2: [
        { lank: '6위', keyword: '#실시간 인기 검색어' },
        { lank: '7위', keyword: '#실시간 인기 검색어' },
        { lank: '8위', keyword: '#실시간 인기 검색어' },
        { lank: '9위', keyword: '#실시간 인기 검색어' },
        { lank: '10위', keyword: '#실시간 인기 검색어' },
      ],
      tabActive: [],
      userInfo: {
        user_id: '',
      },
      menus: [],
      isPartner: false,
      selectedLocale: 'ko',
      selectedImg: '01',
      images: [
        '@/assets/images/common/bg/bg01.jpg',
        '@/assets/images/common/bg/bg02.jpg',
        '@/assets/images/common/bg/bg03.jpg',
        '@/assets/images/common/bg/bg04.jpg',
        '@/assets/images/common/bg/bg05.jpg',
        '@/assets/images/common/bg/bg06.jpg',
        '@/assets/images/common/bg/bg07.jpg',
        '@/assets/images/common/bg/bg08.jpg',
        '@/assets/images/common/bg/bg09.jpg',
        '@/assets/images/common/bg/bg10.jpg',
      ],
    };
  },
  beforeMount() {},
  // beforeMount() {
  //   postData('/menu/log', this.$route)
  //     .then((res) => {
  //       this.$log.debug(['menus res', res]);
  //     })
  //     .catch((err) => {
  //       this.$log.error(['err', err]);
  //     });
  // },
  mounted() {
    this.loadMenu();
    this.height = window.innerHeight;
    this.width = window.innerWidth;
    if (this.$cookies.get('accessToken')) {
      this.isLogin = true;
      this.userInfo = this.$store.getters.getUserInfo;
      this.isPartner = this.userInfo && this.userInfo.is_partner && this.userInfo.partner_status === 'PARTNER_STATUS02';

      // this.$log.debug(['userInfo ', this.userInfo, this.userInfo.isPartner]);
    }

    this.$log.debug(['mounted User', this.userInfo, this.isPartner]);
  },
  watch: {
    $route: {
      handler() {
        // this.$log.debug(['페이지 전환될때 ', this.authByLog(), this.getAccessMenu]);
        this.closeMenu();
      },
    },
    isGnbBind(val) {
      this.$log.debug(['val', val]);
      const rand = Math.floor(Math.random() * 11);

      if (rand > 0) {
        this.selectedImg = rand < 10 ? '0' + rand : rand;
      }
    },
  },
  computed: {
    // async menuList() {
    //   const a = await postData('/menu/log', this.$route);
    //   return a;
    // },
    // menus: {
    //   postData('/menu/log', this.$route).then((res) => {
    //     this.$log.debug(['menus res', res]);
    //   });
    // },
    height: {
      get() {
        return this.debouncedHeight;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.heightTimeout = setTimeout(() => {
          this.debouncedHeight = val;
        }, 500);
      },
    },
    width: {
      get() {
        return this.debouncedWidth;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.widthTimeout = setTimeout(() => {
          this.debouncedWidth = val;
        }, 500);
      },
    },
  },
  methods: {
    loadMenu() {
      this.selectedLocale = this.$i18n.locale;
      getData('/menu').then((res) => {
        this.menus = res.data;
      });
    },
    menuException(menu) {
      this.$log.debug(['menuException', menu]);
      if (menu.menu_path && menu.menu_path.indexOf('Guide') > 0) {
        this.$log.debug(['menu.menu_path', menu.menu_path]);
        let guideType = '';
        switch (menu.menu_path) {
          case '/odm/Guide':
            guideType = 'ODM';
            break;
          case '/obm/Guide':
            guideType = 'OBM';
            break;
          case '/open/Guide':
            guideType = 'OPEN';
            break;
        }
        //
        this.action('guide', guideType);
      } else {
        // 현재 페이지 메뉴정보를 저장한다.
        this.setAccessMenu(menu);
        this.$router.push(menu.menu_path);
      }
      // 특이사항 처리
      // if (menu.menu_path.indexOf('obm') > -1 && menu.menu_path.indexOf('Guide') === -1) {
      //   const accessId = ['company', 'seumconsult', 'seumconsulting', 'engcompany1'];
      //
      //   if (this.userInfo && accessId.includes(this.userInfo.user_id)) {
      //     this.$router.push(menu.menu_path);
      //   } else {
      //     alert(this.t('system.service_ready'));
      //   }
      // } else {
      //   if (menu.menu_path && menu.menu_path.indexOf('Guide') > 0) {
      //     this.$log.debug(['menu.menu_path', menu.menu_path]);
      //     let guideType = '';
      //     switch (menu.menu_path) {
      //       case '/odm/Guide':
      //         guideType = 'ODM';
      //         break;
      //       case '/obm/Guide':
      //         guideType = 'OBM';
      //         break;
      //       case '/open/Guide':
      //         guideType = 'OPEN';
      //         break;
      //     }
      //     //
      //     this.action('guide', guideType);
      //   } else {
      //     // 현재 페이지 메뉴정보를 저장한다.
      //     this.setAccessMenu(menu);
      //     this.$router.push(menu.menu_path);
      //   }
      // }
    },
    accessMenu(menu, isBack) {
      // this.$log.debug(['accessMenu', menu]);
      this.$log.debug(['accessMenu', menu, menu.children, this.selectedLocale, this.userInfo]);

      if (this.selectedLocale === 'ko') {
        // let checmMenu = menu.access_ko;
        //
        // this.$log.debug(['국문체크', menu.access_ko, this.userInfo.user_status]);
        // if (checmMenu >= this.userInfo.user_status) {
        //   this.$log.debug(['같음 체크', checmMenu, this.userInfo.user_status]);
        // }

        // if (checmMenu !== 'ALL' && (!this.userInfo.user_status || checmMenu > this.userInfo.user_status)) {
        if (menu.access_ko !== 'ALL' && (!this.userInfo.user_status || menu.access_ko >= this.userInfo.user_status)) {
          // alert('권한없음');
          alert(this.t('system.not_access'));
          if (isBack) this.$router.go(-1);
        } else {
          this.menuException(menu);
        }
      }

      if (this.selectedLocale === 'en') {
        // this.$log.debug(['영문체크', menu.access_en, this.userInfo.user_status]);

        // 허용을 먼저 해보자.
        // if ((menu.access_en !== 'ALL' && menu.access_en <= this.userInfo.user_status) || menu.access_en === 'ALL') {
        // 차단은?
        // if (menu.access_en !== 'ALL' && (!this.userInfo.user_status || menu.access_en > this.userInfo.user_status)) {
        if (menu.access_en !== 'ALL' && (!this.userInfo.user_status || menu.access_en >= this.userInfo.user_status)) {
          // alert('권한없음');
          alert(this.t('system.not_access'));
          if (isBack) this.$router.go(-1);
          // this.$router.go(-1);
        } else {
          this.menuException(menu);
          // if (isBack) this.$router.go(-1);
        }
      }
    },
    showRequest(menu) {
      if (menu.menu_path.indexOf('odm/Request') > -1 || menu.menu_path.indexOf('obm/Request') > -1) {
        return this.userInfo.user_type === 'USER_TYPE02' || this.userInfo.user_type === 'USER_TYPE04';
      } else {
        return true;
      }
    },
    dataSearch() {
      this.$router.push('/common/Search'); //클릭 해시태그 검색 결과
    },
    changeLocale() {
      this.$log.debug(['changeLocale', this.$i18n.locale, this.getAccessMenu]);

      // 변경 전에 권한을 체크해야한다.
      if (this.getAccessMenu) {
        const menu = this.getAccessMenu;
        if (this.$i18n.locale === 'ko') {
          this.$log.debug(['영문으로 변경 희망']);
          if (menu.access_en !== 'ALL' && menu.access_en >= this.userInfo.user_status) {
            // return
            alert('권한없음');
            return;
            // this.$router.go(-1);
          } else {
            this.$i18n.locale = 'en';
            this.selectedLocale = this.$i18n.locale;
            this.$store.commit('setCurrentLocale', this.selectedLocale);
          }
        } else {
          if (menu.access_ko !== 'ALL' || menu.access_ko >= this.userInfo.user_status) {
            // return
            alert('권한없음');
            return;
          } else {
            this.$i18n.locale = 'ko';
            this.selectedLocale = this.$i18n.locale;
            this.$store.commit('setCurrentLocale', this.selectedLocale);
            // 현재 페이지 메뉴정보를 저장한다.
            // this.setAccessMenu(menu);
            // this.$router.push(menu.menu_path);
          }
        }

        // if (this.selectedLocale === 'en') {
        //   this.$log.debug(['영문체크']);
        //
        // }
        //
        // if (this.$i18n.locale === 'en') {
        //   this.$i18n.locale = 'ko';
        // } else {
        //   this.$i18n.locale = 'en';
        // }
        this.selectedLocale = this.$i18n.locale;
      } else {
        if (this.$i18n.locale === 'en') {
          this.$i18n.locale = 'ko';
        } else {
          this.$i18n.locale = 'en';
        }
        this.selectedLocale = this.$i18n.locale;
        this.$store.commit('setCurrentLocale', this.selectedLocale);
      }

      // console.log(this.$i18n.locale);
    },
    async authByLog() {
      // this.$log.debug(['== authByLog', this.$route, this.selectedLocale]);

      const res = await postData('/menu/log', {
        path: this.$route.path,
        locale: this.$i18n.locale,
      });
      this.$log.debug(['log', res, this.$store.getters.getUserInfo]);
      if (res.data) {
        this.accessMenu(res.data, true);
      }

      // postData('/menu/log', { path: this.$route.path, locale: this.$i18n.locale })
      //   .then((res) => {
      //     this.$log.debug(['log', res, this.$store.getters.getUserInfo]);
      //     const data = res.data;
      //     this.accessMenu(data);
      //   })
      //   .catch((err) => {
      //     this.$log.error(['error', err]);
      //   });
    },
    openMenu() {
      this.height = window.innerHeight;
      this.width = window.innerWidth;

      this.isGnbBind = !this.isGnbBind;
      if (this.width < 768) {
        //mobile
        this.tabActive = [0];
      } else {
        this.tabActive = [0, 1, 2, 3, 4, 5];
      }
      this.modalOverAdd();
    },
    closeMenu() {
      this.isGnbBind = false;
      this.isSearchBind = false;
      this.modalOverRemove();
    },
    openSearchBox() {
      // this.isSearchBind = !this.isSearchBind;
      if (this.$route.path !== '/common/Search') {
        this.$refs.refSearch.isSearchBind = !this.isSearchBind;
      } else {
        this.$router.push(`/common/Search?q=`);
      }
    },
    hoverTopNavi() {
      this.isTopNaviHoverBind = !this.isTopNaviHoverBind;
    },
    resizeHandler() {
      this.height = window.innerHeight;
      this.width = window.innerWidth;

      if (this.isSearchBind == true) {
        if (this.width < 768) {
          //mobile
          this.modalOverAdd();
        } else {
          this.modalOverRemove();
        }
      }
    },
    modalOverAdd() {
      document.documentElement.classList.add('modal-overflow');
      document.body.classList.add('modal-overflow');
    },
    modalOverRemove() {
      document.documentElement.classList.remove('modal-overflow');
      document.body.classList.remove('modal-overflow');
    },
    login() {
      // 현재 페이지를 저장한다.
      if (this.$route.path.indexOf('auth') === -1) {
        this.setCurrentPage(this.$route.path);
      }
      this.$router.push('/auth/Login');
    },
    logout() {
      this.$log.debug(['logout']);
      this.setCurrentPage('');
      this.$store.commit('setUserInfo', null);
      this.$cookies.remove('accessToken');
      this.$cookies.remove('refreshToken');
      // this.$router.push('/main');
      document.location.href = '/main';
    },
    onSubmit() {
      // 폼 검증 완료 후
      this.$log.debug(['onSubmit', this.checkedDrink]);

      this.$confirm.require({
        // message: '폼 전송 완료',
        //header: 'Confirmation',
        // icon: 'pi pi-exclamation-triangle',
        rejectClass: 'd-none',
        accept: () => {
          //callback to execute when user confirms the action
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
        onHide: () => {
          //Callback to execute when dialog is hidden
        },
      });
    },
    action(type, val) {
      this.$log.debug(['action', type, val]);
      switch (type) {
        case 'guide':
          this.emitter.emit('OpenGuide', val);
          this.closeMenu();
          break;
      }
    },
  },

  created() {
    window.addEventListener('resize', this.resizeHandler);
    // 10분 단위 세션 체크
    setInterval(() => {
      this.$log.debug(['check session', new Date(), this.$cookies.get('accessToken')]);
      if (!this.$cookies.get('accessToken')) {
        this.$log.debug('없음');
        // this.logout();
      }
    }, 1000 * 60 * 10);
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler);
  },
};
</script>

<style scoped></style>
