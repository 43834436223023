import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useCurrentPageStore = defineStore("currentPage", () => {
  const currentPage = ref("");
  const getCurrentPage = computed(() => currentPage);
  function setCurrentPage(val) {
    this.currentPage = val;
  }

  return { currentPage, getCurrentPage, setCurrentPage };
});
