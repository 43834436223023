<template>
  <div class="userguide-wrap" :class="{ active: isShowUser }">
    <div class="modal opacity10" @click="closeModal"></div>
    <div class="userguide">
      <div class="userguide-head">
        <button type="button" @click="closeModal" class="btn-userguide-close">
          <img src="@/assets/images/common/ico-24-close.svg" class="ico-24" alt="close" />
        </button>
        <h3 class="eng">USER GUIDE</h3>
        <div class="x-scroll-wrap">
          <div class="tab-list">
            <ul v-dragscroll.x>
              <!-- hor scroll -->
              <li>
                <button type="button" :class="{ active: selectedType === 'ODM' }" @click="action('ODM')">ODM</button>
              </li>
              <li>
                <button type="button" :class="{ active: selectedType === 'OBM' }" @click="action('OBM')">OBM</button>
              </li>
              <li>
                <button type="button" :class="{ active: selectedType === 'OPEN' }" @click="action('OPEN')">
                  OPEN ODM
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="userguide-cont">
        <div class="tab-cont">
          <Accordion v-model:active-index="activeIndex">
            <AccordionTab v-for="userGuide in list" :key="userGuide">
              <template #header>
                <div class="subject">
                  {{ userGuide.title }}
                </div>
              </template>
              <div class="answer" v-html="userGuide.content"></div>
            </AccordionTab>
          </Accordion>
        </div>
        <!-- close .tab-cont -->
      </div>
      <!-- close .userguide-cont -->
    </div>
    <!-- close .userguide -->
  </div>
  <!-- close .userguide-wrap -->
</template>

<script>
import { postData } from '@/serverApi';

export default {
  name: 'UserGuide',
  props: {
    // isUserGuide: Boolean,
    guideType: String,
  },
  watch: {
    // guideType: {
    //   handler() {
    //     this.$log.debug(['guideType', this.guideType, this.selectedType]);
    //     this.selectedType = this.guideType;
    //   },
    //   deep: true,
    // },
    isShowUser: {
      handler() {
        this.$log.debug(['modal guide -==', this.guideType, this.selectedType]);
        this.selectedType = this.guideType;
        this.loadData();
      },
      deep: true,
    },
  },
  data() {
    return {
      isShowUser: false,
      userGuideList: [
        { subject: 'ODM 이란?' },
        { subject: 'Step 1. 기본정보' },
        { subject: 'Step 2. 제형스펙' },
        { subject: 'Step 3. 클레임' },
        { subject: 'Step 4. 향' },
        { subject: 'Step 5. 부자재' },
        { subject: 'Step 6. 수령정보' },
        { subject: 'Step 7. 기타사항' },
        { subject: 'Step 8. 주의사항' },
        { subject: 'Step 9. 내용확인 및 제출' },
      ],
      selectedType: '',
      activeIndex: 0,
      list: [],
    };
  },
  mounted() {
    this.$log.debug(['mounted UserGuide']);
    this.selectedType = this.guideType;
    // load Guide Data
    this.loadData();
  },
  methods: {
    async loadData() {
      /// TODO order: 임시로 역순 처리 라이브시에는 order 삭제 필요.
      const param = {
        board_type: '',
        is_del: 0,
        locale: this.$i18n.locale,
        order: 'recent',
      };

      switch (this.selectedType) {
        case 'ODM':
          param.board_type = 'odm_guide';
          break;
        case 'OBM':
          param.board_type = 'obm_guide';
          break;
        case 'OPEN':
          param.board_type = 'open_guide';
          break;
      }

      const res = await postData('/board/list', param);
      this.$log.debug(['==== loadData', res, res.data]);
      this.list = res.data;
      this.activeIndex = null;
    },
    action(val) {
      this.selectedType = val;
      this.loadData();
    },
    closeModal() {
      this.isShowUser = false;
      document.documentElement.classList.remove('modal-overflow');
      document.body.classList.remove('modal-overflow');
      this.selectedType = 'ODM';
    },
  },
};
</script>

<style scoped></style>
