import VueCookies from 'vue-cookies';
import { createRouter, createWebHistory } from 'vue-router';
import DefaultLayout from '@/pages/DefaultLayout';
import LoginLayout from '@/pages/LoginLayout';

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    redirect: '/main',
  },
  {
    path: '/CheckPlusResult',
    name: 'CheckPlusResult',
    component: () => import('@/pages/auth/CheckPlusResult'),
  },
  {
    path: '/CheckFindIdMobile',
    name: 'CheckFindIdMobile',
    component: () => import('@/pages/auth/CheckFindIdMobile'),
  },
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: 'main',
        name: 'Main',
        component: () => import('@/pages/Main'),
      },
      {
        path: 'sample',
        name: 'Sample',
        component: () => import('@/pages/Sample'),
      },
      {
        path: 'sample2',
        name: 'Sample2',
        component: () => import('@/pages/Sample2'),
      },
    ],
  },
  {
    path: '/common',
    component: DefaultLayout,
    children: [
      {
        path: 'search',
        name: 'Search',
        component: () => import('@/pages/common/Search'),
      },
      {
        path: 'faq',
        name: 'Faq',
        component: () => import('@/pages/common/Faq'),
      },
    ],
  },
  {
    path: '/auth',
    component: LoginLayout,
    // component: DefaultLayout,
    children: [
      {
        path: 'login',
        name: 'AuthLogin',
        component: () => import('@/pages/auth/Login'),
      },
    ],
  },
  {
    path: '/auth',
    component: DefaultLayout,
    children: [
      {
        path: 'FindIdEmail',
        name: 'AuthFindIdEmail',
        component: () => import('@/pages/auth/FindIdEmail'),
      },
      {
        path: 'FindIdMobile',
        name: 'AuthFindIdMobile',
        component: () => import('@/pages/auth/FindIdMobile'),
      },
      {
        path: 'FindIdResult',
        name: 'AuthFindIdResult',
        component: () => import('@/pages/auth/FindIdResult'),
      },
      {
        path: 'FindPw',
        name: 'AuthFindPw',
        component: () => import('@/pages/auth/FindPw'),
      },
      {
        path: 'FindPwCertWay',
        name: 'AuthFindPwCertWay',
        component: () => import('@/pages/auth/FindPwCertWay'),
      },
      {
        path: 'FindPwMobile',
        name: 'AuthFindPwMobile',
        component: () => import('@/pages/auth/FindPwMobile'),
      },
      {
        path: 'FindPwCert',
        name: 'AuthFindPwCert',
        component: () => import('@/pages/auth/FindPwCert'),
      },
      {
        path: 'FindPwResult',
        name: 'AuthFindPwResult',
        component: () => import('@/pages/auth/FindPwResult'),
      },
      {
        path: 'PwReset',
        name: 'AuthPwReset',
        component: () => import('@/pages/auth/PwReset'),
      },
      {
        path: 'SignUp',
        name: 'AuthSignUp',
        component: () => import('@/pages/auth/SignUp'),
      },
      {
        path: 'SignUpTerm',
        name: 'AuthSignUpTerm',
        component: () => import('@/pages/auth/SignUpTerm'),
      },
      {
        path: 'SignUpIndividual',
        name: 'AuthSignUpIndividual',
        component: () => import('@/pages/auth/SignUpIndividual'),
      },
      {
        path: 'SignUpCompany',
        name: 'AuthSignUpCompany',
        component: () => import('@/pages/auth/SignUpCompany'),
      },
      {
        path: 'SignUpGlobalIndividual',
        name: 'AuthSignUpGlobalIndividual',
        component: () => import('@/pages/auth/SignUpGlobalIndividual'),
      },
      {
        path: 'SignUpGlobalCompany',
        name: 'AuthSignUpGlobalCompany',
        component: () => import('@/pages/auth/SignUpGlobalCompany'),
      },
      {
        path: 'SignUpComplete',
        name: 'AuthSignUpComplete',
        component: () => import('@/pages/auth/SignUpComplete'),
      },
    ],
  },
  {
    path: '/about',
    component: DefaultLayout,
    children: [
      {
        path: 'Lab',
        name: 'AboutLab',
        component: () => import('@/pages/about/Lab'),
      },
      {
        path: 'Odm',
        name: 'AboutOdm',
        component: () => import('@/pages/about/Odm'),
      },
      {
        path: 'Partner',
        name: 'AboutPartner',
        component: () => import('@/pages/about/Partner'),
      },
      {
        path: 'Notice',
        name: 'AboutNotice',
        component: () => import('@/pages/about/Notice'),
      },
      {
        path: 'Notice/:board_seq',
        name: 'AboutNoticeDetail',
        component: () => import('@/pages/about/NoticeDetail'),
      },
      // {
      //   path: 'NoticeDetail',
      //   name: 'AboutNoticeDetail',
      //   component: () => import('@/pages/about/NoticeDetail'),
      // },
    ],
  },
  {
    path: '/odm',
    component: DefaultLayout,
    children: [
      {
        path: ':item_code',
        name: 'OdmDetail',
        component: () => import('@/pages/odm/Detail.vue'),
      },
      {
        path: 'Formula/:item_code',
        name: 'OdmFormulaDetail',
        component: () => import('@/pages/odm/Formula'),
      },
      {
        path: 'Formula',
        name: 'OdmFormula',
        component: () => import('@/pages/odm/Formula'),
      },
      {
        path: 'FormulaAll',
        name: 'OdmFormulaAll',
        component: () => import('@/pages/odm/FormulaAll'),
      },
      {
        path: 'Ingredient/:item_code',
        name: 'OdmIngredientDetail',
        component: () => import('@/pages/odm/Ingredient'),
      },
      {
        path: 'Ingredient',
        name: 'OdmIngredient',
        component: () => import('@/pages/odm/Ingredient'),
      },
      {
        path: 'IngredientAll',
        name: 'OdmIngredientAll',
        component: () => import('@/pages/odm/IngredientAll'),
      },
      {
        path: 'Request',
        name: 'OdmRequest',
        component: () => import('@/pages/odm/Request01'),
      },
      {
        path: 'Request01',
        name: 'OdmRequest01',
        component: () => import('@/pages/odm/Request01'),
      },
      {
        path: 'Request011',
        name: 'odmrequeset',
        component: () => import('@/pages/odm/Request01'),
      },
      {
        path: 'Request02',
        name: 'OdmRequest02',
        component: () => import('@/pages/odm/Request02'),
      },
      {
        path: 'Request03',
        name: 'OdmRequest03',
        component: () => import('@/pages/odm/Request03'),
      },
      {
        path: 'Request04',
        name: 'OdmRequest04',
        component: () => import('@/pages/odm/Request04'),
      },
      {
        path: 'Request05',
        name: 'OdmRequest05',
        component: () => import('@/pages/odm/Request05'),
      },
      {
        path: 'Request06',
        name: 'OdmRequest06',
        component: () => import('@/pages/odm/Request06'),
      },
      {
        path: 'Request07',
        name: 'OdmRequest07',
        component: () => import('@/pages/odm/Request07'),
      },
      {
        path: 'Request08',
        name: 'OdmRequest08',
        component: () => import('@/pages/odm/Request08'),
      },
      {
        path: 'RequestComplete',
        name: 'OdmRequestComplete',
        component: () => import('@/pages/odm/RequestComplete'),
      },
    ],
  },
  {
    path: '/obm',
    component: DefaultLayout,
    children: [
      {
        path: 'Inventory',
        name: 'ObmInventory',
        component: () => import('@/pages/obm/Inventory'),
      },
      {
        path: 'Inventory/:board_seq',
        name: 'ObmInventoryDetail',
        component: () => import('@/pages/obm/InventoryDetail'),
      },
      {
        path: 'InventoryDetail',
        name: 'ObmInventoryDetail2',
        component: () => import('@/pages/obm/InventoryDetail'),
      },
      {
        path: 'Request',
        name: 'ObmRequest',
        component: () => import('@/pages/obm/Request01'),
      },
      {
        path: 'Request01',
        name: 'ObmRequest01',
        component: () => import('@/pages/obm/Request01'),
      },
      {
        path: 'Request02',
        name: 'ObmRequest02',
        component: () => import('@/pages/obm/Request02'),
      },
      {
        path: 'Request03',
        name: 'ObmRequest03',
        component: () => import('@/pages/obm/Request03'),
      },
      {
        path: 'Request04',
        name: 'ObmRequest04',
        component: () => import('@/pages/obm/Request04'),
      },
      {
        path: 'RequestComplete',
        name: 'ObmRequestComplete',
        component: () => import('@/pages/obm/RequestComplete'),
      },
    ],
  },
  {
    path: '/open',
    component: DefaultLayout,
    children: [
      {
        path: 'Formula',
        name: 'OpenFormula',
        component: () => import('@/pages/open/Formula'),
      },
      {
        path: 'FormulaAll',
        name: 'OpenFormulaAll',
        component: () => import('@/pages/open/FormulaAll'),
      },
      {
        path: 'Ingredient',
        name: 'OpenIngredient',
        component: () => import('@/pages/open/Ingredient'),
      },
      {
        path: 'IngredientAll',
        name: 'OpenIngredientAll',
        component: () => import('@/pages/open/IngredientAll'),
      },
      {
        path: 'Packaging',
        name: 'OpenPackaging',
        component: () => import('@/pages/open/Packaging'),
      },
      {
        path: 'PackagingAll',
        name: 'OpenPackagingAll',
        component: () => import('@/pages/open/PackagingAll'),
      },
      {
        path: 'MyItem',
        name: 'OpenMyItem',
        component: () => import('@/pages/open/MyItem'),
      },
      {
        path: 'MyItemCategory',
        name: 'OpenMyItemCategory',
        component: () => import('@/pages/open/MyItemCategory'),
      },
      {
        path: 'RegFormula',
        name: 'OpenRegFormula',
        component: () => import('@/pages/open/RegFormula'),
      },
      {
        path: 'RegIngredient',
        name: 'OpenRegIngredient',
        component: () => import('@/pages/open/RegIngredient'),
      },
      {
        path: 'RegPackaging',
        name: 'OpenRegPackaging',
        component: () => import('@/pages/open/RegPackaging'),
      },
    ],
  },
  {
    path: '/trend',
    component: DefaultLayout,
    children: [
      {
        path: 'Report',
        name: 'TrendReport',
        component: () => import('@/pages/trend/Report'),
      },
      {
        path: 'News',
        name: 'TrendNews',
        component: () => import('@/pages/trend/News'),
      },
      {
        path: 'Request',
        name: 'TrendRequest',
        component: () => import('@/pages/trend/Request'),
      },
      {
        path: 'Complete',
        name: 'TrendComplete',
        component: () => import('@/pages/trend/Complete'),
      },
    ],
  },
  {
    path: '/mypage',
    component: DefaultLayout,
    children: [
      {
        path: 'CheckAuth',
        name: 'MypageCheckAuth',
        component: () => import('@/pages/mypage/CheckAuth'),
      },
      {
        path: 'MemIndividual',
        name: 'MypageMemIndividual',
        component: () => import('@/pages/mypage/MemIndividual'),
      },
      {
        path: 'MemCompany',
        name: 'MypageMemCompany',
        component: () => import('@/pages/mypage/MemCompany'),
      },
      {
        path: 'MemGlobalIndividual',
        name: 'MypageMemGlobalIndividual',
        component: () => import('@/pages/mypage/MemGlobalIndividual'),
      },
      {
        path: 'MemGlobalCompany',
        name: 'MypageMemGlobalCompany',
        component: () => import('@/pages/mypage/MemGlobalCompany'),
      },
      {
        path: 'MemWithdrawal',
        name: 'MypageMemWithdrawal',
        component: () => import('@/pages/mypage/MemWithdrawal'),
      },
      {
        path: 'MyRequest',
        name: 'MypageMyRequest',
        component: () => import('@/pages/mypage/MyRequest'),
      },
      {
        path: 'WishList',
        name: 'MypageWishList',
        component: () => import('@/pages/mypage/WishList'),
      },
      {
        path: 'RecentList',
        name: 'MypageRecentList',
        component: () => import('@/pages/mypage/RecentList'),
      },
      {
        path: 'Inquery',
        name: 'MypageInquery',
        component: () => import('@/pages/mypage/Inquery'),
      },
      {
        path: 'InqueryReg',
        name: 'MypageInqueryReg',
        component: () => import('@/pages/mypage/InqueryReg'),
      },
      {
        path: 'Inquery/:board_seq',
        name: 'MypageInqueryDetail',
        component: () => import('@/pages/mypage/InqueryDetail'),
      },
      {
        path: 'InqueryDetail',
        name: 'MypageInqueryDetail2',
        component: () => import('@/pages/mypage/InqueryDetail'),
      },
      {
        path: 'OdmRequest',
        name: 'MypageOdmRequest',
        component: () => import('@/pages/mypage/OdmRequest'),
      },
      {
        path: 'ObmRequest',
        name: 'MypageObmRequest',
        component: () => import('@/pages/mypage/ObmRequest'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    console.log(['=== scrollBehavior', to, from]);
    window.scrollTo(0, 0);
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 });
      }, 500);
    });
  },
});

router.beforeEach((to, from, next) => {
  console.debug(['beforeEach', to, from, next]);
  console.debug(['cookie', VueCookies.get('accessToken')]);
  if (VueCookies.get('accessToken') && to.path.indexOf('auth') > -1) {
    next('/');
  }

  // if (!VueCookies.get('accessToken') && to.path.indexOf('mypage') > -1) {
  //   next('/auth/Login');
  // }
  next();
});

export default router;
