<template>
    <div class="cont-wrapper">
        <app-header />
        <div id="container">
            <router-view />
        </div>
        <app-footer />
    </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
export default {
  name: "DefaultLayout",
  components: {AppFooter, AppHeader}
}
</script>

<style scoped>

</style>
