<template>
  <div class="search-wrap" :class="{ active: isSearchBind }">
    <div class="search-header">
      <div class="cont-wrap">
        <div class="logo">
          <img src="@/assets/images/common/logo.svg" alt="cosmocos lab logo" />
        </div>
        <button type="button" @click="closeMenu" class="btn-search-close">
          <img src="@/assets/images/common/ico-24-close.svg" class="ico-24" alt="close" />
        </button>
      </div>
    </div>
    <Form @submit="onSubmit">
      <div class="cont-wrap search-common-wrap w664">
        <div class="input-wrap">
          <label for="h_combine_search">
            <!-- 검색어를 입력하세요 -->
            {{ $t('common.search_label') }}
          </label>
          <div class="form-chk">
            <input
              ref="searchBox"
              name="h_combine_search"
              id="h_combine_search"
              type="text"
              class="input-default"
              v-model="searchWord"
            />
            <!-- 스프레드시트 : 검색창에서 기존에 입력한 검색어 한번에 지우는 기능 (X 버튼) 추가 가능한가요? -->
            <button type="button" class="btn-input-reset">
              <img src="@/assets/images/common/ico-20-del.svg" class="ico-24" alt="reset" />
            </button>
            <button type="submit" class="btn-combine-search">
              <img src="@/assets/images/common/ico-24-search.svg" class="ico-24" alt="search" />
            </button>
          </div>
        </div>
        <div class="recommend" v-show="showRecommend">
          <dl>
            <dt>
              <!-- 추천 검색어 -->
              {{ $t('common.search_recommend') }}
            </dt>
            <dd>
              <ul>
                <li v-for="recommend in recommendList" :key="recommend">
                  <button type="button" @click="setKeyword(recommend)">#{{ recommend }}</button>
                </li>
              </ul>
            </dd>
          </dl>
        </div>
        <!-- close .recommend -->
        <div class="popular" v-show="showPopular">
          <h3>
            {{ $t('common.search_popular') }}
          </h3>
          <ul>
            <li v-for="(popular, i) in popularList1" :key="i">
              <dl>
                <dt>{{ i + 1 }}</dt>
                <dd>
                  <button type="button" @click="setKeyword(popular.term)">#{{ popular.term }}</button>
                </dd>
              </dl>
            </li>
          </ul>
          <ul v-if="popularList2.length > 0">
            <li v-for="(popular, i) in popularList2" :key="i">
              <dl>
                <dt>{{ i + 6 }}</dt>
                <dd>
                  <button type="button" @click="setKeyword(popular.term)">#{{ popular.term }}</button>
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </div>
      <!-- close .cont-wrap -->
    </Form>
  </div>
</template>

<script>
// import { Form, Field } from 'vee-validate';
import { Form } from 'vee-validate';
import { useSearchTermsStore } from '@/stores/searchTerms';
import { getData } from '@/serverApi';
export default {
  name: 'SearchBar',
  components: {
    Form,
    // Field,
  },
  watch: {
    isSearchBind: {
      handler() {
        this.$log.debug(['isBind handler', this.isSearchBind]);
        if (this.isSearchBind) {
          this.searchWord = '';
          this.setFocus();
          this.getSearchConfig();
          this.selectedLocale = this.$i18n.locale;
        }
      },
      deep: true,
    },
  },
  data() {
    const searchTerms = useSearchTermsStore();
    const { setTerms } = searchTerms;
    return {
      setTerms,
      isSearchBind: false,
      recommendList: [{ tag: '#해시태그' }, { tag: '#해시태그' }, { tag: '#해시태그' }, { tag: '#해시태그' }],
      popularList1: [],
      popularList2: [],
      showRecommend: 0,
      showPopular: 0,
      selectedLocale: 'ko',
      searchWord: '',
      config: {},
      topKeywordsKo: [],
      topKeywordsEn: [],
    };
  },
  mounted() {
    this.setFocus();
    // document.getElementById('h_combine_search').focus();
    // if (this.isSearchBind == true) {
    //
    // }
    if (this.width < 768) {
      //mobile
      this.modalOverAdd();
    } else {
      this.modalOverRemove();
    }
  },
  methods: {
    getSearchConfig() {
      getData('/search/config').then((res) => {
        this.$log.debug(['search configres', res, this.selectedLocale]);
        this.config = res.data.config;
        if (this.selectedLocale === 'ko') {
          // 추천 검색어 표시여부
          this.showRecommend = this.config.recommend_ko_show;
          // 인기 검색어 표시여부
          this.showPopular = this.config.popular_ko_show;

          // 추천 검색어
          if (this.config.recommend_ko_keyword) {
            this.recommendList = this.config.recommend_ko_keyword.split(',');
          }

          if (this.showPopular && res.data.topKeywordsKo) {
            this.popularList1 = res.data.topKeywordsKo.filter((value, index) => index < 5);
            if (res.data.topKeywordsKo.length > 5) {
              this.popularList2 = res.data.topKeywordsKo.filter((value, index) => index > 4 && index < 10);
            } else {
              this.popularList2 = [];
            }
          } else {
            this.popularList1 = [];
            this.popularList2 = [];
          }

          // this.popularList1 = '';
        }

        if (this.selectedLocale === 'en') {
          // 추천 검색어 표시여부
          this.showRecommend = this.config.recommend_en_show;
          // 인기 검색어 표시여부
          this.showPopular = this.config.popular_en_show;

          if (this.config.recommend_en_keyword) {
            this.recommendList = this.config.recommend_en_keyword.split(',');
          }

          if (this.showPopular && res.data.topKeywordsEn) {
            this.popularList1 = res.data.topKeywordsEn.filter((value, index) => index < 5);
            if (res.data.topKeywordsEn.length > 5) {
              this.popularList2 = res.data.topKeywordsEn.filter((value, index) => index > 4 && index < 10);
            } else {
              this.popularList2 = [];
            }
          } else {
            this.popularList1 = [];
            this.popularList2 = [];
          }
        }
      });
    },
    setFocus() {
      this.$log.debug(['setFocus', this.$refs.searchBox]);
      setTimeout(() => {
        this.$refs.searchBox.focus();
      }, 300);
      // this.$nextTick(() => this.$refs.searchBox.focus());
    },
    modalOverAdd() {
      document.documentElement.classList.add('modal-overflow');
      document.body.classList.add('modal-overflow');
    },
    modalOverRemove() {
      document.documentElement.classList.remove('modal-overflow');
      document.body.classList.remove('modal-overflow');
    },
    setKeyword(term) {
      this.searchWord = term;
      this.dataSearch();
    },
    dataSearch() {
      this.$log.debug(['dataSearch', this.searchWord]);
      if (this.searchWord) {
        // this.setTerms(this.searchWord);
        // this.$router.push('/common/Search'); //클릭 해시태그 검색 결과
        this.$router.push(`/common/Search?q=${this.searchWord}`);
        this.closeMenu();
      }
    },
    onSubmit() {
      // 폼 검증 완료 후
      this.$log.debug(['onSubmit']);
      this.dataSearch();

      this.$confirm.require({
        // message: '폼 전송 완료',
        //header: 'Confirmation',
        // icon: 'pi pi-exclamation-triangle',
        rejectClass: 'd-none',
        accept: () => {
          //callback to execute when user confirms the action
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
        onHide: () => {
          //Callback to execute when dialog is hidden
        },
      });
    },
    closeMenu() {
      this.isGnbBind = false;
      this.isSearchBind = false;
      this.modalOverRemove();
    },
  },
};
</script>

<style scoped lang="scss">
.btn-input-reset {
  display: none;
  position: absolute;
  top: 0;
  right: 52px;
  width: 52px;
  height: 52px;
  text-align: right;
  > img {
    width: 16px;
    height: 16px;
  }
}
</style>
