<template>
    <div class="modal-wrap" :class="{ active: isShow }">
        <div class="modal" @click="closeModal"></div>
        <div class="modal-alert-wrap">
            <div class="modal-head">
                <button type="button" @click="closeModal" class="btn-modal-close">
                    <img src="@/assets/images/common/ico-24-close.svg" class="ico-24" alt="close">
                </button>
            </div> 
            <div class="modal-cont">
                <p class="desc" v-html="$t('modal_alert.cont')"></p>
            </div> 
            <div class="modal-action dual">
                <button type="button" class="item btn btn-sub" @click="closeModal">
                    {{ $t('modal_alert.btn_no') }}
                </button>
                <router-link to="/auth/SignUp" class="item btn">
                    {{ $t('modal_alert.btn_submit') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "ModalAlert",
  data() {
    return {
      isShow: false
    }
  },
  mounted() {
  },
  watch: {
    $route: {
      handler() {
        this.closeModal();
      }
    }
  },
  methods: {
    closeModal() {
      this.isShow = false;
      document.documentElement.classList.remove("modal-overflow");
      document.body.classList.remove("modal-overflow");
    }
  }
}
</script>

<style scoped>
</style>