import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useSearchTermsStore = defineStore("searchTerms", () => {
  const terms = ref("");
  const getTerms = computed(() => terms);
  function setTerms(val) {
    this.terms = val;
  }

  return { terms, getTerms, setTerms };
  // state: () => {
  //   return { terms: null };
  // },
  // // 다음과 같이 정의할 수도 있음:
  // // state: () => ({ count: 0 })
  // getters: {
  //   terms: (state) => state.terms,
  // },
  // actions: {
  //   setTerms(value) {
  //     this.terms = value;
  //   },
  // },
});
