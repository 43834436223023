<template>
  <div class="modal-wrap" :class="{ active: isShowMem }">
    <div class="modal"></div>

    <!-- 시작 : v-if 준회원 안내팝업 : 국영문 모두 삭제하고 바로 로그인 화면으로 연결-->
    <div class="modal-alert-wrap" v-if="modalType === 'regular'">
      <div class="modal-head">
        <button type="button" @click="closeModal" class="btn-modal-close">
          <img src="@/assets/images/common/ico-24-close.svg" class="ico-24" alt="close" />
        </button>
      </div>
      <div class="modal-cont font15">
        <p class="desc" v-html="$t('modal_asso.cont_grade')"></p>
      </div>
      <div class="modal-action">
        <a href="javascript:;" @click="action('/auth/signUp')" class="item btn btn-line">
          {{ $t('modal_asso.btn_signup') }}
        </a>
      </div>
    </div>
    <!-- close .modal-alert-wrap -->
    <!-- 종료 : -->

    <!-- 시작 : v-if M3-3-0_ODM REQUEST_비자격 안내문구 : 국영문 모두 삭제하고 바로 로그인 화면으로 연결 -->
    <div class="modal-alert-wrap" v-if="modalType === 'associate'">
      <div class="modal-head">
        <button type="button" @click="closeModal" class="btn-modal-close">
          <img src="@/assets/images/common/ico-24-close.svg" class="ico-24" alt="close" />
        </button>
      </div>
      <div class="modal-cont font15">
        <p class="desc" v-html="$t('modal_asso.cont_unqualified')"></p>
      </div>
      <div class="modal-action">
        <a href="javascript:;" @click="action('/auth/login')" class="item btn btn-line">
          {{ $t('modal_asso.btn_login') }}
        </a>
      </div>
    </div>
    <!-- close .modal-alert-wrap -->
    <!-- 종료 : -->

    <!-- 시작 : v-if 등록권한 일시중지 -->
    <div class="modal-alert-wrap" v-if="modalType === 'stop'">
      <div class="modal-head">
        <button type="button" @click="closeModal" class="btn-modal-close">
          <img src="@/assets/images/common/ico-24-close.svg" class="ico-24" alt="close" />
        </button>
      </div>
      <div class="modal-cont font15">
        <p class="desc" v-html="$t('modal_asso.cont_stop')"></p>
      </div>
      <div class="modal-action">
        <a href="javascript:;" @click="action('/mypage/inqury')" to="/auth/SignUp" class="item btn btn-line">
          {{ $t('modal_asso.btn_inquery') }}
        </a>
      </div>
    </div>
    <!-- close .modal-alert-wrap -->
    <!-- 종료 : -->

    <!-- 시작 : v-if M6-3-1_INVENTORY REQUEST_비회원자격 접근시 안내 -->
    <div class="modal-alert-wrap" v-if="modalType === 'inventory'">
      <div class="modal-head">
        <button type="button" @click="closeModal" class="btn-modal-close">
          <img src="@/assets/images/common/ico-24-close.svg" class="ico-24" alt="close" />
        </button>
      </div>
      <div class="modal-cont font15">
        <p class="desc" v-html="$t('modal_asso.cont_unqualified_inven')"></p>
      </div>
      <div class="modal-action dual">
        <a href="javascript:;" @click="action('/auth/login')" class="item btn btn-line">
          {{ $t('modal_asso.btn_login') }}
        </a>
        <a href="javascript:;" @click="action('/auth/signup')" class="item btn">
          {{ $t('modal_asso.btn_signup') }}
        </a>
      </div>
    </div>
    <!-- close .modal-alert-wrap -->
    <!-- 종료 : -->

    <!-- custom  : 한국어만... -->
    <div class="modal-alert-wrap" v-if="modalType === 'custom'">
      <div class="modal-head">
        <button type="button" @click="closeModal" class="btn-modal-close">
          <img src="@/assets/images/common/ico-24-close.svg" class="ico-24" alt="close" />
        </button>
      </div>
      <div class="modal-cont font15">
        <p class="desc" v-html="modalContent"></p>
      </div>
      <div class="modal-action">
        <a href="javascript:;" @click="closeModal" class="item btn btn-line"> 닫기 </a>
      </div>
    </div>
  </div>
</template>

<script>
import { useCurrentPageStore } from '@/stores/currentPage';

export default {
  name: 'ModalAssociateMem',
  props: {
    modalType: {
      type: String,
      default: null,
    },
    modalContent: String,
  },
  data() {
    // const detailItems = useDetailItemStore();
    // const { item, getItem, setItem } = detailItems;
    const store = useCurrentPageStore();
    const { setCurrentPage } = store;
    return {
      isShowMem: false,
      setCurrentPage,
    };
  },
  mounted() {},
  watch: {
    $route: {
      handler() {
        this.closeModal();
      },
    },
  },
  methods: {
    action(val) {
      if (val.indexOf('login') > -1) {
        this.$log.debug(['currentPage save', this.$route.path]);
        this.setCurrentPage(this.$route.path);
      }
      this.closeModal();
      this.$router.push(val);
    },
    closeModal() {
      this.isShowMem = false;
      document.documentElement.classList.remove('modal-overflow');
      document.body.classList.remove('modal-overflow');
    },
  },
};
</script>

<style scoped></style>
