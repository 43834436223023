<template>
  <div class="userguide-wrap" :class="{ active: isShowUser }">
    <div class="modal opacity10" @click="closeModal"></div>
    <div class="userguide">
      <div class="userguide-head">
        <button type="button" @click="closeModal" class="btn-userguide-close">
          <img src="@/assets/images/common/ico-24-close.svg" class="ico-24" alt="close" />
        </button>
        <h3 class="eng">LAB Library</h3>
      </div>
      <div class="userguide-cont">
        <div class="tab-cont">
          <Accordion>
            <AccordionTab v-for="userGuide in list" :key="userGuide">
              <template #header>
                <div class="subject">
                  {{ userGuide.title }}
                </div>
              </template>
              <div class="answer" v-html="userGuide.content"></div>
            </AccordionTab>
          </Accordion>
        </div>
        <!-- close .tab-cont -->
      </div>
      <!-- close .userguide-cont -->
    </div>
    <!-- close .userguide -->
  </div>
  <!-- close .userguide-wrap -->
</template>

<script>
import { postData } from '@/serverApi';

export default {
  name: 'LabLibrary',
  props: {
    // isUserGuide: Boolean,
    guideType: String,
  },
  watch: {
    guideType: {
      handler() {
        this.$log.debug(['guideType', this.guideType, this.selectedType]);
        this.selectedType = this.guideType;
      },
      deep: true,
    },
    isShowUser: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },
  data() {
    return {
      isShowUser: false,
      selectedType: '',
      list: [],
    };
  },
  mounted() {
    this.$log.debug(['mounted UserGuide']);
    this.selectedType = this.guideType;
    // load Guide Data
    this.loadData();
  },
  methods: {
    async loadData() {
      /// TODO order: 임시로 역순 처리 라이브시에는 order 삭제 필요.
      const param = {
        board_type: 'lab_library',
        // is_del: 0,
        locale: this.$i18n.locale,
        order: 'recent',
      };

      const res = await postData('/board/list', param);
      this.$log.debug(['==== loadData', res, res.data]);
      this.list = res.data;
    },
    closeModal() {
      this.isShowUser = false;
      document.documentElement.classList.remove('modal-overflow');
      document.body.classList.remove('modal-overflow');
    },
  },
};
</script>

<style scoped>
.cont-wrapper .userguide-wrap > .userguide > .userguide-cont {
  margin-top: 115px;
}
</style>
