export default {
  execDaumPostcode(obj) {
    obj.zipcode = '';
    obj.address = '';
    obj.address_etc = '';

    new window.daum.Postcode({
      oncomplete: (data) => {
        if (obj.address_etc !== '') {
          obj.address_etc = '';
        }
        if (data.userSelectedType === 'R') {
          // 사용자가 도로명 주소를 선택했을 경우
          obj.address = data.roadAddress;
        } else {
          // 사용자가 지번 주소를 선택했을 경우(J)
          obj.address = data.jibunAddress;
        }

        // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
        if (data.userSelectedType === 'R') {
          // 법정동명이 있을 경우 추가한다. (법정리는 제외)
          // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
          if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
            obj.address_etc += data.bname;
          }
          // 건물명이 있고, 공동주택일 경우 추가한다.
          if (data.buildingName !== '' && data.apartment === 'Y') {
            obj.address_etc += obj.address_etc !== '' ? `, ${data.buildingName}` : data.buildingName;
          }
          // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
          if (obj.address_etc !== '') {
            obj.address_etc = `(${obj.address_etc})`;
          }
        } else {
          obj.address_etc = '';
        }
        // 우편번호를 입력한다.
        obj.zipcode = data.zonecode;
      },
    }).open();
  },
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  },
  fileToImg(file) {
    return URL.createObjectURL(file);
  },
  splitComma(data) {
    return data.split(',');
  },
  isDev() {
    return process.env.NODE_ENV === 'development';
  },
  gbFnReplaceXss(val) {
    return val
      .replaceAll('<', '&lt;')
      .replaceAll('>', '&gt')
      .replaceAll("'", '&#x27;')
      .replaceAll('"', '&quot;')
      .replaceAll('\\(', '&#40;')
      .replaceAll('\\)', '&#41;')
      .replaceAll('\\\\', '&#x2F;');
  },
};
